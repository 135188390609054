<template>
  <div class="form-wrap tw-flex tw-flex-col md:tw-flex-row">
    <div
      v-for="(option, index) in productUiOptions"
      :key="option.optionCode"
      :class="{
        'input-field select-input tw-w-full tw-m-0': true,
        'tw-mr-2': index < productUiOptions.length - 1,
        'max-md:tw-mb-5': index < productUiOptions.length - 1,
      }"
    >
      <select
        v-model="selectedOptions[option.optionCode]"
        :name="option.optionCode"
        :id="`product-option-` + option.optionCode"
        :disabled="isCartUpdateInProgress"
        required
        class="tw-m-0"
        @change="onChangeOption"
      >
        <option disabled selected value="">{{ option.label }}</option>
        <option
          v-for="value in option.values"
          :key="value.id"
          :value="value.id"
          :disabled="!value.isCombinationAvailable"
          v-text="getOptionLabel(value)"
        ></option>
      </select>

      <label class="label" :for="`product-option-` + option.optionCode">
        {{ option.label }}
      </label>
      <IconArrow />
    </div>

    <div v-if="isCartUpdateInProgress" class="skeleton-loader" />
  </div>
</template>

<script>
import { inject } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';

import IconArrow from '@/components/icons/IconArrow.vue';

export default {
  name: 'ProductOptions',
  components: {
    IconArrow,
  },
  data() {
    return {
      selectedOptions: {},
    };
  },
  async mounted() {
    const cartStore = useCartStore();

    this.appInstance = inject('appInstance');

    // A temporary fix to the error page being shown when coming from thankyou page
    if (this.cart.transactionId) {
      return;
    }

    if (this.getIsProductOptionsSelectionComplete) {
      // Options not already set in state, maybe navigated back from another screen i.e. card flow
      this.setStateOptionsAsSelected();
    } else {
      // Set the default options
      await this.setFirstAvailableCombinationAsSelected();
    }

    // Auto apply discount - this is a variant product so the auto set is called here, after the default
    // selected options have been set
    if (this.getIsAutoApplyDiscount) {
      cartStore.setDiscounted();
    }
  },
  computed: {
    ...mapState(useProductStore, [
      'getProductUiOptions',
      'variantsMatrix',
      'getProductVariantFromOptions',
    ]),
    ...mapState(useCartStore, [
      'cart',
      'getIsProductOptionSelectionAvailable',
      'getIsProductOptionsSelectionComplete',
      'combinationAvailability',
      'isCartUpdateInProgress',
      'selectedProductOptions',
      'getIsAutoApplyDiscount',
    ]),

    // Add empty/ default option to each drop down menu and set deffault value of isCombinationAvailable, where isCombinationAvailable
    // is a flag indicating whether the option should be enabled or disabled based on the seleciton of the other fields.
    productUiOptions() {
      return this.getProductUiOptions.map((variantOptions) => ({
        ...variantOptions,
        values: [
          {
            id: '-1',
            label: `Please select a ${variantOptions.label}`,
            isCombinationAvailable: true,
          },
        ].concat(
          variantOptions.values.map((val) => ({
            ...val,
            isCombinationAvailable:
              this.combinationAvailability[variantOptions.optionCode][val.id].isAvailable,
          })),
        ),
      }));
    },
  },
  methods: {
    ...mapActions(useMainStore, ['currency']),
    ...mapActions(useShippingStore, ['resetShippingMethods']),
    ...mapActions(useCartStore, ['setProductOptions']),

    /**
     * Return Option with price label if price is greater than 0
     */
    getOptionLabel(option) {
      let price = null;

      if (option.price && option.price > 0) {
        price = option.price;
      }

      return option.label + (price ? ' ( +' + this.formatPrice(price) + ' )' : '');
    },

    formatPrice(price) {
      return `${this.currency.symbol}${parseFloat(price).toFixed(2)}`;
    },

    onChangeOption(e) {
      this.resetShippingMethods();

      return this.setProductOptions(
        Object.entries(this.selectedOptions).map(([variantCode, optionValue]) => ({
          variantCode,
          optionValue,
        })),
      );
    },

    setStateOptionsAsSelected() {
      Object.entries(this.selectedProductOptions).forEach(([variantCode, variantId]) => {
        this.selectedOptions[variantCode] = variantId;
      });
    },

    setFirstAvailableCombinationAsSelected() {
      const firstAvailableSelection = Object.values(this.variantsMatrix).find(
        (variant) => variant.isInStock,
      );

      if (firstAvailableSelection) {
        firstAvailableSelection.variantOptions.forEach(({ variantCode, variantId }) => {
          this.selectedOptions[variantCode] = variantId;
        });

        return this.onChangeOption();
      } else {
        console.error('Could not set default options as no available selection matched!');
      }
    },
  },
  watch: {
    selectedProductOptions: {
      handler(newVal) {
        if (newVal) {
          const productVariant = this.getProductVariantFromOptions(newVal);

          if (productVariant && productVariant.image) {
            // Update the image gallery with the selected variant image if one is available
            this.appInstance.config.globalProperties.$eventEmitter.emit('productSelectionChanged', {
              imageSrc: productVariant.image,
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
