<template>
  <div>
    <h4>Your card details</h4>

    <div>
      <!-- Cardholder name -->
      <div id="card-name" class="bthf-field"></div>

      <!-- Card number -->
      <div id="card-number" class="bthf-field"></div>

      <!-- Expiration Date -->
      <div id="card-expiration-date" class="bthf-field"></div>

      <!-- CVV -->
      <div id="card-cvv" class="bthf-field"></div>

      <div v-if="!isFormValid" class="colour-danger pb-10 pl-5 pr-5">
        Some fields are incorrect, please check and try again.
      </div>

      <div v-if="errorMessage" class="colour-danger pb-10 pl-5 pr-5">{{ errorMessage }}</div>

      <button
        class="button button--primary button--full-width"
        @click="doSubmit()"
        :disabled="isLoading"
        :loading="isLoading"
      >
        <div v-if="isLoading" class="st-spinner" />
        <span v-else>Purchase</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import * as Sentry from '@sentry/vue';
import { HostedFieldsPaymentError } from '@/types/errors.types';

const ERROR_MESSAGES = {
  generic: 'We were unable to complete your payment, please check your details and try again.',
};

export default {
  name: 'PPCPCard',
  components: {},
  props: {
    paypalApi: { type: Object, required: false }, // Can be overriden in tests
  },
  data() {
    return {
      isRenderComponent: true,
      cardFields: null,
      isFormValid: true,
      errorMessage: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(useShippingStore, ['address']),
    ...mapState(useCartStore, ['cart']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
  },
  async mounted() {
    await this.init();
  },
  beforeUnmount() {
    if (this.cardFields?.close) {
      this.cardFields.close();
    }
  },
  methods: {
    ...mapActions(useMainStore, ['goToErrorPage', 'setPaymentButtonInitialised']),
    ...mapActions(usePaymentStore, ['placePPCPOrder']),

    onPaymentError(error) {
      if (this.cardFields?.close) {
        this.cardFields.close();
      }

      this.goToErrorPage({
        error,
      });
    },

    async init() {
      const paypalApi = this.paypalApi || window.paypal;

      try {
        const cardFields = paypalApi.CardFields({
          createOrder: () => {
            return Promise.resolve(this.cart.pspOrderId);
          },
          onApprove: async (data) => {
            const { liabilityShift } = data;
            let errorMsg = null;

            // Check 3DS liability (see https://developer.paypal.com/docs/checkout/advanced/customize/3d-secure/response-parameters/#link-liabilityshift)
            if (['NO', 'UNKNOWN'].includes(liabilityShift)) {
              // 3DS verification failed
              errorMsg = 'We were unable to verify your card';
              console.debug(`Failed 3DS check with liability shift: ${liabilityShift}`);
            } else {
              // 3DS verification passed
              try {
                await this.placeOrder();
              } catch (err) {
                // If the errorCode is anticipated show message to user else go to Error page
                if (err.name === 'PPCPPaymentCaptureError') {
                  errorMsg = ERROR_MESSAGES.generic;
                  console.debug('PPCPPaymentCaptureError occurred', err.sourceError);

                  try {
                    const processorResponse =
                      err.sourceError.response.data.error.processor_response;

                    console.debug('processorResponse:', processorResponse);
                  } catch (er) {
                    console.error('An error occurred in unpacking Processor Response!', er);
                    Sentry.captureException(err);
                  }
                } else {
                  return this.onPaymentError(
                    new HostedFieldsPaymentError('Unexpected error in order capture!', err),
                  );
                }
              }
            }

            if (errorMsg) {
              this.errorMessage = errorMsg;
              console.error(`Cannot place order as something has erred on submission! ${errorMsg}`);
            }

            this.isLoading = false;
          },
          onError: (err) => {
            console.error('An error occurred in the payment!', err);

            this.isLoading = false;

            if (err.message.includes('UNPROCESSABLE_ENTITY')) {
              this.errorMessage = ERROR_MESSAGES.generic;
              return;
            }

            return this.onPaymentError(
              new HostedFieldsPaymentError('Error in card payment!', err, this.paymentProvider),
            );
          },
          style: {
            input: {
              // 'font-size': '16px',
              // 'font-family': 'courier, monospace',
              'font-weight': 'bold',
              // border: 'none',
              color: 'black',
              // 'border-color': 'red',
              height: '1px',
            },
            '.invalid': {
              color: 'red',
            },
          },
        });

        const nameField = cardFields.NameField({
          /*
          style: {
            input: {
              color: 'blue',
            },
          },
          */
        });
        nameField.render('#card-name');

        const numberField = cardFields.NumberField();
        numberField.render('#card-number');

        const expiryField = cardFields.ExpiryField();
        expiryField.render('#card-expiration-date');

        const cvvField = cardFields.CVVField();
        cvvField.render('#card-cvv');

        this.cardFields = cardFields;
      } catch (err) {
        throw new HostedFieldsPaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    async doSubmit() {
      try {
        this.errorMessage = null;
        this.isLoading = true;
        this.isFormValid = true;

        const cardState = await this.cardFields.getState();

        // Submit only if the form is valid
        if (cardState.isFormValid) {
          await this.cardFields.submit({
            billingAddress: {
              addressLine1: this.address.billing.streetAddress[0],
              addressLine2: this.address.billing.streetAddress[1],
              adminArea1: this.address.billing.city,
              adminArea2: this.address.billing.region,
              countryCode: this.address.billing.country,
              postalCode: this.address.billing.postalCode,
            },
          });
        } else {
          this.isFormValid = false;
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;

        console.error('Submission error!', err);

        if (err.message.includes('UNPROCESSABLE_ENTITY')) {
          // Handled by onError()
          return;
        }

        // E.g ignore 'Window closed for postrobot_method before response'
        if (!err.message.includes('Window closed')) {
          throw new HostedFieldsPaymentError('Error in onSubmit()!', err, this.paymentProvider);
        }
      }

      return Promise.resolve(true);
    },

    async placeOrder() {
      this.placePPCPOrder('card');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
