import type { Router } from 'vue-router';

// Using this export rather than the default export to resolve Circular Dependancies in unit testing.
export const getRouter = async (): Promise<Router> => {
  /**
   * You could try something like this in the future if needed, leaving here for prosperity - DS 2/5/24
   */
  // const routerFile = window.Cypress ? '.stubbed' : '.actual';
  // const router = await import(`./router${routerFile}.js`);

  let router;

  if (window.Cypress && import.meta.env.VITE_APP_IS_IN_E2E !== 'true') {
    router = await import(`./router.stubbed.js`);
  } else {
    router = await import(`./router.js`);
  }

  return router.default;
};
