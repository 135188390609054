<template>
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 27.5C22.4037 27.5 28 21.9037 28 15C28 8.09625 22.4037 2.5 15.5 2.5C8.59625 2.5 3 8.09625 3 15C3 21.9037 8.59625 27.5 15.5 27.5Z"
      stroke="#CD081E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 7.8L16.68 17.46H14.32L13.98 7.8H17ZM15.56 22.14C15.0533 22.14 14.6333 21.9867 14.3 21.68C13.98 21.36 13.82 20.9667 13.82 20.5C13.82 20.0333 13.98 19.6467 14.3 19.34C14.6333 19.02 15.0533 18.86 15.56 18.86C16.0533 18.86 16.46 19.02 16.78 19.34C17.1 19.6467 17.26 20.0333 17.26 20.5C17.26 20.9667 17.1 21.36 16.78 21.68C16.46 21.9867 16.0533 22.14 15.56 22.14Z"
      fill="#CD081E"
    />
  </svg>
</template>
