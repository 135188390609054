/**
 * When a dialogue is launched, adds a query param flag. This is then used in prevnting
 * the user from being navigated back and away from the checkout when using the browser
 * back button when on e.g. the product gallery image overlay.
 */
import { getRouter } from '@/router';
import type { App } from 'vue';

const dialoguesInEffectCloseFunctions: Function[] = [];

type DialogueListenerParams = {
  isDialogueInEffect: boolean; // Set the dialogue state to 'a dialogue is open'
  onDialogueClose: Function; // Run when invoked by e.g. browser back button if dialogue(s) present
  doCloseAllDialogues: boolean; // // Run when invoked by e.g. browser back button
};

export default {
  install: (app: App): void => {
    app.config.globalProperties.$dialogueListener = async ({
      isDialogueInEffect,
      onDialogueClose = () => {},
      doCloseAllDialogues,
    }: DialogueListenerParams) => {
      const router = await getRouter();

      dialoguesInEffectCloseFunctions.push(onDialogueClose);

      if (doCloseAllDialogues) {
        dialoguesInEffectCloseFunctions.forEach((fn) => fn());
        dialoguesInEffectCloseFunctions.length = 0; // clear the array
        return;
      }

      if (isDialogueInEffect) {
        router.push({
          name: router.currentRoute.value.name,
          query: { isDialogueInEffect: 'true' },
        });
      } else {
        router.back();
        onDialogueClose();
      }
    };
  },
};
