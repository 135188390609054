import { defineStore } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { CartComplete } from '@/types/cart.types';
import { getRouter } from '@/router';

// import { type ConfirmationToken } from '@stripe/stripe-js';
import type { ConfirmationToken } from '@stripe/stripe-js';

import type {
  AdyenPaymentDataPayPal,
  AdyenPaymentDataGooglePay,
  AdyenPaymentDataCard,
} from '@/types/payment.adyen.types';

import type { PaymentButtonType } from '@/types/paymentMethods.types';

type PaymentEnvironment = 'sandbox' | 'production';
type PaymentConfigBase = {
  // TODO split away token etc. when not used in PSP implementation e.g. PPCP
  paymentProviderToken: string;
  paymentProviderName: string;
  // adyen - clientKey
  // adyen - adyenPaymentMethods
  environment: PaymentEnvironment | null;
  paymentMethods: PaymentButtonType[];
};

export type PaymentConfigPPCP = PaymentConfigBase & {
  merchantId: string;
};

const mockServerUrl = import.meta.env.VITE_LOCAL_NODE_SERVER_URL;

export type State = {
  totals: {
    subtotal: number;
    shipping: number;
    grand: number;
  };
  supports: {
    paymentRequest: boolean;
    payPal: boolean;
  };
  payment: {
    nonce: string;
    method: string;
  };
  braintreePaymentData: {
    nonce: string;
    type: string;
    niceType: string;
    last4: string;
    method: string;
    bin: string | null;
  };
  stripePaymentData: {
    confirmationToken: ConfirmationToken | null;
    amount: number | null;
    currencyCode: string | null;
  };
  adyenPaymentData:
    | AdyenPaymentDataPayPal
    | AdyenPaymentDataGooglePay
    | AdyenPaymentDataCard
    | null;
  orderId: string | null;
  paymentConfig: PaymentConfigBase | PaymentConfigPPCP;
};

const getInitialState = (): State => ({
  totals: {
    subtotal: 0,
    shipping: 0,
    grand: 0,
  },
  supports: {
    paymentRequest: typeof window.PaymentRequest === 'function',
    payPal: true,
  },
  payment: {
    nonce: '',
    method: '',
  },
  braintreePaymentData: {
    nonce: '',
    type: '',
    niceType: '',
    last4: '',
    method: '',
    bin: null,
  },
  stripePaymentData: {
    confirmationToken: null,
    amount: null,
    currencyCode: null,
  },
  adyenPaymentData: null,
  orderId: null,
  paymentConfig: {
    paymentProviderToken: '',
    paymentProviderName: '',
    environment: null,
    paymentMethods: [],
  },
});

export const usePaymentStore = defineStore('paymentStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    setPaymentConfig(paymentConfig: ToDo) {
      this.paymentConfig = paymentConfig;
    },

    setAdyenDetails(clientKey: string, paymentMethods) {
      this.transaction.clientKey = clientKey;
      this.transaction.adyenPaymentMethods = paymentMethods;
    },

    setBraintreePaymentData(data) {
      this.braintreePaymentData = data;
    },

    getAdyenPaymentMethod(state) {
      const method = state.data.paymentMethod.type === 'scheme' ? 'adyen_cc' : 'adyen_hpp';
      return {
        method,
        additional_data: {
          brand_code: state.data.paymentMethod.type,
          stateData: JSON.stringify(state.data),
        },
      };
    },

    formatPrice(price: string | number) {
      const mainStore = useMainStore();

      return `${mainStore.currency.symbol}${parseFloat(price).toFixed(2)}`;
    },

    setBraintreePaymentNonce(value: string) {
      this.braintreePaymentData.nonce = value;
    },

    setStripePaymentData(data: State['stripePaymentData']) {
      this.stripePaymentData = data;
    },

    setAdyenPaymentData(data: State['adyenPaymentData']) {
      this.adyenPaymentData = data;
    },

    async placeBraintreeOrder() {
      const mainStore = useMainStore();
      const cartStore = useCartStore();
      const router = await getRouter();

      mainStore.startAppLoading();

      try {
        await cartStore.setOrdered({
          nonce: this.braintreePaymentData.nonce,
          method: this.braintreePaymentData.method,
        });

        const cart = cartStore.cart as CartComplete;

        this.orderId = cart!.transactionId;

        router.replace({ name: 'Thankyou' });
      } finally {
        mainStore.endAppLoading();
      }
    },

    async placePPCPOrder(paymentMethod: PaymentButtonType) {
      const mainStore = useMainStore();
      const cartStore = useCartStore();
      const router = await getRouter();

      mainStore.startAppLoading();

      try {
        const success = await cartStore.setOrdered({
          method: paymentMethod,
        });

        if (success) {
          const cart = cartStore.cart as CartComplete;

          this.orderId = cart!.transactionId;
          router.replace({ name: 'Thankyou' });
        } else {
          throw new Error('Failed to set Cart as Ordered!');
        }
      } finally {
        mainStore.endAppLoading();
      }
    },

    async placeStripeOrder() {
      const mainStore = useMainStore();
      const { confirmationToken, amount, currencyCode } = this.stripePaymentData;

      mainStore.startAppLoading();

      try {
        // Confirm the PaymentIntent
        const confirmationResponse = await fetch(`https://${mockServerUrl}/create-confirm-intent`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            confirmationTokenId: confirmationToken?.id,
            amount,
            currency: currencyCode,
          }),
        });

        const confirmationResponseData = await confirmationResponse.json();

        console.log('FINAL confirmationResponseData DATA', confirmationResponseData);

        if (confirmationResponseData.error) {
          throw confirmationResponseData.error;
        }

        // TODO check that confirmationResponseData.status is 'succeeded'
      } finally {
        mainStore.endAppLoading();
      }
    },

    async placeAdyenOrder() {
      const mainStore = useMainStore();
      const cartStore = useCartStore();
      const router = await getRouter();

      mainStore.startAppLoading();

      try {
        await cartStore.setOrderedAdyen(this.adyenPaymentData);

        const cart = cartStore.cart as CartComplete;

        this.orderId = cart!.transactionId;

        router.replace({ name: 'Thankyou' });
      } finally {
        mainStore.endAppLoading();
      }
    },
  },

  getters: {
    getIsPaymentMethodEnabled:
      (state) =>
      (paymentMethod: PaymentButtonType): boolean => {
        return state.paymentConfig.paymentMethods.includes(paymentMethod);
      },
  },
});
