import { Sentry } from '@/helpers/moduleMockWrappers/sentry';
import sendEventApi from '@/api/eventTracker/index';
import { getPosthog } from '@/plugins/postHog';
import { useCartStore } from '@/stores/CartStore';
import { ExpectedEvents, ExpectedInternalTrackingEvents } from '@/types/events.types';

import type { ExpectedInternalTrackingEventsType, ExpectedEventsType } from '@/types/events.types';
import type { CartJwtPayload } from '@/types/cart.types';

type TrackEventArgs = { event: ExpectedEventsType; data?: unknown };

export type InternalEventPayload = {
  event_name: ExpectedEventsType;
  event: undefined;
  data: unknown;
};

// TODO Add a type for external event type payload, maybe useth posthog ty[e]

const expectedPaymentTypes = ['paypal', 'googlepay', 'applepay', 'card'];

// @throws
const doValidateEvent = (args: TrackEventArgs) => {
  let isValid = true;

  if (!args) {
    console.error('No event args supplied!');
    isValid = false;
  } else if (!args?.event) {
    console.error('No event supplied!');
    isValid = false;
  } else if (!ExpectedEvents.includes(args?.event)) {
    console.error('Unrecognised event supplied!');
    isValid = false;
  }

  // Custom event validation
  switch (args.event) {
    case 'payment_type_selected':
      if (!expectedPaymentTypes.includes(args?.data?.paymentType)) {
        isValid = false;
      }
      break;

    default:
      break;
  }

  if (!isValid) {
    throw new Error(`Invalid event args: ${JSON.stringify(args)}`);
  }
};

export const trackEvent = (args: TrackEventArgs) => {
  const cartStore = useCartStore();
  const posthog = getPosthog();
  const { cart, cartJwt } = cartStore;

  if (import.meta.env.VITE_APP_ENV === 'local') {
    console.log('Tracking event:', args);
  }

  try {
    // TODO check if error prop is of type 'Error' and then transform to string.

    doValidateEvent(args);

    // Internal B/E tracking
    if (ExpectedInternalTrackingEvents.includes(args.event as ExpectedInternalTrackingEventsType)) {
      if (cart) {
        if (!cart.id) {
          const msg = `Skipping event tracking for event ${args.event} as no Cart ID available.`;

          console.debug(msg);
          Sentry.captureException(msg);
        } else {
          sendEventApi.sendEvent(
            {
              ...args,
              event_name: args?.event,
              event: undefined,
              data: args?.data || {},
            },
            cart.id,
            cartJwt as CartJwtPayload,
          );
        }
      } else {
        console.debug(`Skipping event tracking for event ${args.event} as no Cart available.`);
      }
    }

    // External tracking
    // TODO add a test for this
    posthog.capture(args.event, args.data as {});
  } catch (err: any) {
    console.error('Failed to track event!', err);
    Sentry.captureException(err, { extra: args });
  }
};

export default {
  trackEvent,
};
