<template>
  <div
    v-if="getIsShowPricingElements"
    class="pricing-and-qty-container"
    data-test="main-pricing-labelling"
  >
    <div class="price-wrapper">
      <div
        v-if="isCartUpdateInProgress"
        aria-label="Loading"
        class="st-spinner qty-change-spinner"
      />

      <div
        v-else
        class="price-container"
        data-test="price-container"
        :class="discountLookupState === 'DISCOUNT_APPLIED' ? 'sale-container' : ''"
      >
        <span>Price</span>
        <span class="price">
          <span data-test="normal-price" v-text="getFormattedPrice(getTotalCartPrice)"></span>
          <span
            v-if="
              getFormattedPrice(getTotalCartPrice) !==
              getFormattedPrice(getSelectedProductRegularPriceDisplay)
            "
            class="original-price"
          >
            {{
              `/Originally ${discountLookupState === 'DISCOUNT_APPLIED' ? getFormattedPrice(cart.pricing.totalUnitPrice) : getFormattedPrice(cart.pricing.unitPrice)}`
            }}
          </span>
        </span>

        <span v-if="discountLookupState === 'DISCOUNT_APPLIED'" class="discount-summary">
          Discounted by {{ getFormattedPrice(Math.abs(cart.pricing.totalDiscount)) }}
        </span>

        <span v-if="discountLookupState === null" v-text="'Discounts not applied'"></span>
      </div>
    </div>

    <ProductQty
      data-test="product-quantity-display"
      :quantity="cart?.productQuantity"
      :onIncrementQuantity="incrementQuantity"
      :onDecrementQuantity="decrementQuantity"
      :onSetQuantity="setQuantity"
    />
  </div>

  <div
    v-else-if="
      isProductHasVariants &&
      getIsProductOptionsSelectionComplete &&
      !getIsProductOptionsSelectionValid
    "
    class="colour-danger"
    data-test="invalid-option-selection-message"
  >
    <span
      v-if="!getIsProductOptionsSelectionHasPricingData || !getIsProductOptionsSelectionInStock"
    >
      Sorry, that product selection is currently out of stock.
    </span>
    <span v-else> No products match your selected options, please try another combination. </span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';

import ProductQty from '@/components/ProductQty/index.vue';
export default {
  name: 'PriceAndQty',
  components: {
    ProductQty,
  },
  computed: {
    ...mapState(useMainStore, ['getIsShopFrontInitialised', 'getIsShowPricingElements']),
    ...mapState(useProductStore, ['product', 'discountLookupState', 'isProductHasVariants']),
    ...mapState(useCartStore, [
      'cart',
      'getFormattedPrice',
      'getTotalCartPrice',
      'isCartUpdateInProgress',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'getIsProductOptionsSelectionHasPricingData',
      'getIsProductOptionsSelectionInStock',
    ]),

    getSelectedProductRegularPriceDisplay() {
      return window.Cypress ? 123 : this.getSelectedProductRegularPrice();
    },
  },
  methods: {
    ...mapActions(useCartStore, [
      'incrementQuantity',
      'decrementQuantity',
      'setQuantity',
      'getSelectedProductRegularPrice',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
