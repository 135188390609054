import * as Sentry from '@sentry/vue';
import api from '../Api';

import type { CartJwtPayload } from '@/types/cart.types';
import type { InternalEventPayload } from '@/helpers/eventTracker';

const sendEvent = async (event: InternalEventPayload, cartId: string, cartJwt: CartJwtPayload) => {
  if (window.Cypress) {
    return;
  }

  api
    .post(`checkout/cart/${cartId}/checkout-events`, event, {
      headers: {
        'Cart-Jwt': cartJwt,
      },
    })
    .catch((err) => {
      console.error('Failed to send internal tracking event!', err);
      Sentry.captureException(err);
    });
};

export default {
  sendEvent,
};
