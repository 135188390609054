<template>
  <div
    v-if="isRenderComponent"
    ref="googlePayContainer"
    id="google-pay-container"
    style="height: 45px"
  ></div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { GooglePaymentError } from '@/types/errors.types';
import constants from '@/constants';
import { Utils } from '@/helpers/utils';
import { Sentry } from '@/helpers/moduleMockWrappers/sentry';

const userErrorMessage = 'An error has occurred!';

export default {
  name: 'PPCPGooglePay',
  props: {
    paypalApi: { type: Object, required: false }, // Can be overriden in tests
    googleApi: { type: Object, required: false }, // Can be overriden in tests
  },
  data() {
    return {
      googlePay: null,
      isRenderComponent: true,
    };
  },
  computed: {
    ...mapState(useMainStore, ['currency']),
    ...mapState(usePaymentStore, ['paymentConfig', 'getIsPaymentMethodEnabled']),
    ...mapState(useShippingStore, ['address', 'shippingMethods']),
    ...mapState(useCartStore, ['cart', 'getTotalPaymentPrice']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
  },
  async mounted() {
    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'paypal',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    await Utils.awaitInstanceInitialised({
      object: window,
      property: 'google',
      timeout: constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT,
    });

    this.init();
  },
  methods: {
    ...mapActions(useMainStore, ['goToErrorPage', 'setPaymentButtonInitialised']),
    ...mapActions(useCartStore, ['setShippingMethod']),
    ...mapActions(usePaymentStore, ['setBraintreePaymentData', 'placePPCPOrder']),
    ...mapActions(useShippingStore, ['fetchShippingMethods', 'setCustomer', 'setAddress']),

    getDisplayItems(isIncludeShipping) {
      const rtn = [];

      rtn.push({
        label: 'Subtotal',
        type: 'SUBTOTAL',
        price: (this.cart.pricing.totalUnitPrice || 0).toString(),
        status: 'FINAL',
      });

      rtn.push({
        label: 'Discount',
        type: 'LINE_ITEM',
        price: (this.cart.pricing.totalDiscount || 0).toString(),
        status: 'FINAL',
      });

      if (isIncludeShipping) {
        rtn.push({
          label: 'Shipping',
          type: 'LINE_ITEM',
          price: (this.cart.pricing.shippingPrice || 0).toString(),
          status: 'FINAL',
        });
      }

      return rtn;
    },

    onPaymentError(error) {
      this.goToErrorPage({
        error,
      });
    },

    async getGooglePayConfig() {
      const googlepayConfig = await this.googlePay.config();

      if (googlepayConfig.isEligible && this.getIsPaymentMethodEnabled('googlepay')) {
        return googlepayConfig;
      }

      console.log('Google Pay is not supported.');
    },

    async init() {
      const paypalApi = this.paypalApi || window.paypal;
      const googleApi = this.googleApi || window.google;
      const storeCurrency = this.currency;
      let googleClient;

      try {
        this.googlePay = paypalApi.Googlepay();

        const googlePayContainer = this.$refs.googlePayContainer;

        const paymentsClientProps = {
          environment: this.paymentConfig.environment === 'production' ? 'PRODUCTION' : 'TEST',
          paymentDataCallbacks: {
            onPaymentDataChanged: async (intermediatePaymentData) => {
              try {
                const result = await this.onPaymentDataChanged(intermediatePaymentData);
                return result;
              } catch (err) {
                return this.onPaymentError(
                  new GooglePaymentError(
                    'Error in onPaymentDataChanged()!',
                    err,
                    this.paymentProvider,
                  ),
                );
              }
            },
            onPaymentAuthorized: async (paymentData) => {
              try {
                const result = await this.onPaymentAuthorized(paymentData);
                return result;
              } catch (err) {
                return new Promise((res) => {
                  Sentry.captureException(err);

                  res({
                    error: {
                      reason: 'PAYMENT_DATA_INVALID',
                      intent: 'PAYMENT_AUTHORIZATION',
                      message: userErrorMessage,
                    },
                  });
                });
              }
            },
          },
        };

        if (window.Cypress) {
          googleClient = googleApi.payments.api.PaymentsClient;
          googleClient.initStubs(paymentsClientProps);
        } else {
          googleClient = new googleApi.payments.api.PaymentsClient(paymentsClientProps);
        }

        const googlePayConfig = await this.getGooglePayConfig();

        if (!googlePayConfig) {
          this.setPaymentButtonInitialised('googlepay', 'NOT_ENABLED');
          this.isRenderComponent = false;
          return;
        }

        const createPaymentDataRequest = () => {
          const requestProps = {
            apiVersion: googlePayConfig.apiVersion,
            apiVersionMinor: googlePayConfig.apiVersionMinor,
            allowedPaymentMethods: googlePayConfig.allowedPaymentMethods,
            merchantInfo: googlePayConfig.merchantInfo,
            transactionInfo: {
              displayItems: this.getDisplayItems(),
              countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
              currencyCode: storeCurrency.currencyCode,
              totalPriceStatus: 'ESTIMATED',
              totalPrice: (this.getTotalPaymentPrice || 0).toString(),
              totalPriceLabel: 'Total',
            },
            shippingAddressRequired: true,
            shippingOptionRequired: true,
            shippingAddressParameters: {
              allowedCountryCodes: constants.ALLOWED_SHIPPING_COUNTRY_CODES,
            },
            emailRequired: true,
            callbackIntents: ['PAYMENT_AUTHORIZATION', 'SHIPPING_ADDRESS', 'SHIPPING_OPTION'],
          };

          // Set required billing address capture
          requestProps.allowedPaymentMethods.forEach((method) => {
            method.parameters.billingAddressRequired = true;
            method.parameters.billingAddressParameters = {
              format: 'FULL',
              phoneNumberRequired: true,
            };
          });

          return requestProps;
        };

        const isReadyToPayResponse = await googleClient.isReadyToPay({
          apiVersion: googlePayConfig.apiVersion,
          apiVersionMinor: googlePayConfig.apiVersionMinor,
          allowedPaymentMethods: googlePayConfig.allowedPaymentMethods,
          existingPaymentMethodRequired: true, // Optional
        });

        if (isReadyToPayResponse.result) {
          const button = await googleClient.createButton({
            buttonColor: 'black',
            buttonType: 'buy',
            buttonSizeMode: 'fill',
            buttonRadius: 100,
            allowedPaymentMethods: googlePayConfig.allowedPaymentMethods,
            onClick: async (event) => {
              event.preventDefault();

              this.onSubmit({
                paymentsClient: googleClient,
                paymentDataRequest: createPaymentDataRequest(),
              });
            },
          });

          googlePayContainer.appendChild(button);

          this.setPaymentButtonInitialised('googlepay', 'INITIALISED');
        }
      } catch (err) {
        throw new GooglePaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    async onPaymentDataChanged(intermediatePaymentData) {
      try {
        const storeCurrency = this.currency;

        let paymentDataRequestUpdate = {};
        let newShippingOptions = [];
        let shippingOptionData = intermediatePaymentData.shippingOptionData;

        let address = {
          streetAddress: [''],
          city: intermediatePaymentData.shippingAddress.locality,
          region: intermediatePaymentData.shippingAddress.administrativeArea,
          postalCode: intermediatePaymentData.shippingAddress.postalCode,
          country: intermediatePaymentData.shippingAddress.countryCode,
        };

        this.setAddress('billing', address);
        this.setAddress('shipping', address);

        await this.fetchShippingMethods();

        (this.shippingMethods || []).forEach((method) => {
          newShippingOptions.push({
            id: method.code,
            label: `${method.label} (${method.amount} ${storeCurrency.currencyCode})`,
            description: '',
          });
        });

        if (newShippingOptions.length) {
          const currentShipping =
            shippingOptionData.id === 'shipping_option_unselected'
              ? newShippingOptions[0].id
              : shippingOptionData.id;

          // Set shipping methods in google modal
          paymentDataRequestUpdate.newShippingOptionParameters = {
            defaultSelectedOptionId: currentShipping,
            shippingOptions: newShippingOptions,
          };

          // Update Shipping code to selected code
          await this.setShippingMethod(currentShipping);
        } else {
          paymentDataRequestUpdate.newShippingOptionParameters = {
            defaultSelectedOptionId: 'shipping_option_unselected',
            shippingOptions: [
              {
                id: 'shipping_option_unselected',
                label: 'No shipping available',
                description: '',
              },
            ],
          };

          paymentDataRequestUpdate.error = {
            reason: 'SHIPPING_ADDRESS_UNSERVICEABLE',
            message: 'No shipping methods are available for the selected address',
            intent: 'SHIPPING_ADDRESS',
          };
        }

        paymentDataRequestUpdate.newTransactionInfo = {
          displayItems: this.getDisplayItems(true),
          countryCode: constants.PAYMENT_PROCESSING_COUNTRY_CODE,
          currencyCode: storeCurrency.currencyCode,
          totalPriceStatus: 'FINAL',
          totalPrice: (this.getTotalPaymentPrice || 0).toString(),
          totalPriceLabel: 'Total',
        };

        return paymentDataRequestUpdate;
      } catch (err) {
        throw new GooglePaymentError('Error in onPaymentDataChanged()!', err, this.paymentProvider);
      }
    },

    async onPaymentAuthorized(paymentData) {
      try {
        await this.googlePay.confirmOrder({
          orderId: this.cart.pspOrderId,
          paymentMethodData: paymentData.paymentMethodData,
        });

        return Promise.resolve({ transactionState: 'SUCCESS' });
      } catch (err) {
        throw new GooglePaymentError('Error in onPaymentAuthorized()!', err, this.paymentProvider);
      }
    },

    // @see https://developers.google.com/pay/api/web/reference/response-objects#PaymentData
    handleGooglePayPayload(data) {
      try {
        const shipping = data.shippingAddress;
        const billing = data.paymentMethodData.info.billingAddress;

        const shippingAddress = {
          streetAddress: [shipping.address1, shipping.address2 ?? '', shipping.address3 ?? ''],
          city: shipping.locality,
          postalCode: shipping.postalCode,
          region: shipping.administrativeArea,
          country: shipping.countryCode,
        };

        const billingAddress = {
          streetAddress: [billing.address1, billing.address2 ?? '', billing.address3 ?? ''],
          city: billing.locality,
          postalCode: billing.postalCode,
          region: billing.administrativeArea,
          country: billing.countryCode,
        };

        this.setCustomer('shipping', {
          firstName: shipping.name,
          lastName: shipping.name,
        });

        this.setCustomer('billing', {
          firstName: billing.name,
          lastName: billing.name,
          phone: billing.phoneNumber,
          email: data.email,
        });

        this.setAddress('shipping', shippingAddress);
        this.setAddress('billing', billingAddress);
      } catch (err) {
        throw new GooglePaymentError(
          'Error in handleGooglePayPayload()!',
          err,
          this.paymentProvider,
        );
      }
    },

    async onSubmit({ paymentDataRequest, paymentsClient }) {
      try {
        const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);

        this.handleGooglePayPayload(paymentData);
        this.placePPCPOrder('googlepay');
      } catch (err) {
        const errorMsgWhiteList = ['User closed the Payment Request UI'];

        if (!errorMsgWhiteList.some((listItem) => err.message?.includes(listItem))) {
          throw new GooglePaymentError('Error in onSubmit()!', err, this.paymentProvider);
        }
      }
    },
  },
};
</script>
