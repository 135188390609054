<template>
  <div id="product-wrap">
    <div v-if="getIsShopFrontInitialised">
      <div id="product-selection-and-pricing-container">
        <div v-if="getIsShowPricingElements" data-test="main-pricing-labelling">
          <div
            :class="{
              'tw-items-center': true,
              'price-and-qty-container': true,
              'tw-flex tw-justify-between': !isProductHasVariants && $isScreenSizeLessThan('m'),
            }"
            ref="pricingRef"
          >
            <!-- Pricing -->
            <div class="price-container tw-pr-2" data-test="price-container">
              <Prices />
            </div>

            <!-- Quantity Selection -->
            <ProductQty
              :class="{
                'tw-pt-3': isProductHasVariants,
                'md:tw-pt-3': true,
              }"
              data-test="product-quantity-display"
              :quantity="cart?.productQuantity"
              :onIncrementQuantity="incrementQuantity"
              :onDecrementQuantity="decrementQuantity"
              :onSetQuantity="setQuantity"
            />
          </div>
        </div>

        <div
          v-else-if="
            isProductHasVariants &&
            getIsProductOptionsSelectionComplete &&
            !getIsProductOptionsSelectionValid
          "
          class="colour-danger"
          data-test="invalid-option-selection-message"
        >
          <span
            v-if="
              !getIsProductOptionsSelectionHasPricingData || !getIsProductOptionsSelectionInStock
            "
          >
            Sorry, that product selection is currently out of stock.
          </span>
          <span v-else>
            No products match your selected options, please try another combination.
          </span>
        </div>
      </div>
    </div>

    <div v-else class="skeleton-loader" />

    <div class="summary-text" v-if="advertiserSummaryText">
      {{ advertiserSummaryText }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useCartStore } from '@/stores/CartStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';

import Prices from '@/components/Prices/index.vue';
import ProductQty from '@/components/ProductQty/index.vue';

export default {
  name: 'ProductComp',
  components: {
    Prices,
    ProductQty,
  },
  data() {
    return {
      pricingRef: null,
    };
  },
  computed: {
    ...mapState(useMainStore, ['getIsShopFrontInitialised', 'getIsShowPricingElements']),
    ...mapState(useProductStore, ['product', 'discountLookupState', 'isProductHasVariants']),
    ...mapState(useAdvertiserStore, ['advertiserSummaryText']),
    ...mapState(useCartStore, [
      'cart',
      'getFormattedPrice',
      'getTotalCartPrice',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'getIsProductOptionsSelectionHasPricingData',
      'getIsProductOptionsSelectionInStock',
    ]),

    getSelectedProductRegularPriceDisplay() {
      return window.Cypress ? 123 : this.getSelectedProductRegularPrice();
    },
  },
  methods: {
    ...mapActions(useCartStore, [
      'incrementQuantity',
      'decrementQuantity',
      'setQuantity',
      'getSelectedProductRegularPrice',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
