import { defineStore } from 'pinia';
import { useCartStore } from '@/stores/CartStore';
import { useProductStore } from '@/stores/ProductStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { Sentry } from '@/helpers/moduleMockWrappers/sentry';
import { getRouter } from '@/router';
import constants from '@/constants';

import type { CartJwtPayload } from '@/types/cart.types';
import type { State as PaymentStoreState } from '@/stores/PaymentStore';

import { paymentButtonTypes } from '@/types/paymentMethods.types';
import type {
  PaymentButtonType,
  PaymentButtonInitialisationStateType,
} from '@/types/paymentMethods.types';
import type { Router } from 'vue-router';

export type PaymentPopupDataType = {
  launchState: null | 'IS_LAUNCHING' | 'IS_CLOSED';
  cartJwt: null | CartJwtPayload;
  paymentConfig: null | PaymentStoreState['paymentConfig'];
  selectedVariant: ToDo;
};

export type State = {
  code: string | null;
  parentLocation: Location | null;
  loading: {
    app: boolean;
    splash: boolean;
  };
  initialisedPaymentButtons: Record<PaymentButtonType, PaymentButtonInitialisationStateType>;
  isPaymentButtonsInitialised: boolean;
  currentlyOpenInfoTab:
    | 'PRODUCT_INFO'
    | 'PRODUCT_DELIVERY'
    | 'PRODUCT_DETAILS'
    | 'PRODUCT_FEATURES'
    | 'TERMS_AND_CONDITIONS';
  isPreviewMode: boolean;
  snackBarMessage: string | null;

  // PDP (Product Details Page) denotes a custom template for users viewing the Checkout embedded in an external website, e.g. in an influencers PDP website.
  isPDPView: boolean;
  isPaymentPopup: boolean;
  currency: {
    currencyCode: string;
    symbol: string;
  };
  // Set in the parent window and read from the popup window via window.opener.ParentVueInstance
  paymentPopupState: PaymentPopupDataType;
  userSupportedApplePayVersion: null | number;
  templateType?: string;
};

const getInitialState = (): State => ({
  code: null,
  parentLocation: null,
  loading: {
    app: true,
    splash: false,
  },
  // TODO this is also in payment store + I don't like it
  supports: {
    paymentRequest: typeof window.PaymentRequest === 'function',
    payPal: true,
  },

  // Just until we can get it from the server - it should really be null for now DS 31/1/24
  // Then @todo - wait until all required initial things are loaded on launch and handle errors accordingly - DS.
  currency: {
    currencyCode: 'GBP',
    symbol: '£',
  },
  // Keeps a record of what XHR requests are in progress so we can present loading indication accordingly
  xhrTracker: {
    blocksUI: [],
  },
  initialisedPaymentButtons: {
    card: 'INITIALISED',
    googlepay: null,
    applepay: null,
    paypal: null,
  },
  isPaymentButtonsInitialised: false,
  currentlyOpenInfoTab: 'PRODUCT_DETAILS',
  isPreviewMode: false,
  isPDPView: false,
  isPaymentPopup: false,
  paymentPopupState: {
    cartJwt: null,
    launchState: null,
    paymentConfig: null,
    selectedVariant: null,
  },
  userSupportedApplePayVersion: null,
  templateType: import.meta.env.VITE_TEMPLATE,
  snackBarMessage: null,
});

export const useMainStore = defineStore('mainStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    async setData({
      code,
      parentLocation,
      isPreviewMode,
      isPDPView,
      isPaymentPopup,
      userSupportedApplePayVersion,
    }: {
      isPaymentPopup: boolean;
      code: string | null;
      parentLocation: string | null;
      isPreviewMode: boolean;
      isPDPView: boolean;
      userSupportedApplePayVersion: string | null;
    }) {
      try {
        const productStore = useProductStore();
        const cartStore = useCartStore();

        // Parse the parentLocation string passed from parent window
        let parentLocationObject = null;

        try {
          parentLocationObject = JSON.parse(parentLocation);
        } catch (err) {
          console.error(`Failed to parse parentLocation ${parentLocation}`, err);
        }

        this.parentLocation = parentLocationObject;
        this.isPreviewMode = isPreviewMode;
        this.isPDPView = isPDPView;
        this.isPaymentPopup = isPaymentPopup;
        this.userSupportedApplePayVersion =
          userSupportedApplePayVersion === null ||
          userSupportedApplePayVersion === 'null' ||
          isNaN(Number(userSupportedApplePayVersion))
            ? null
            : parseInt(userSupportedApplePayVersion, 10);

        if (this.isPaymentPopup) {
          // Take just what's needed for Popup Window from Parent Window state
          const { cartJwt, paymentConfig, selectedVariant } = window.opener.ParentVueInstance
            .paymentPopupState as PaymentPopupDataType;

          this.paymentPopupState = {
            launchState: null,
            cartJwt,
            paymentConfig,
            selectedVariant,
          };

          await cartStore.initAsPaymentPopup({
            cartJwt: this.paymentPopupState.cartJwt,
            paymentConfig: this.paymentPopupState.paymentConfig,
          });

          this.code = cartStore.cart!.shortCode;

          // Fetch static Product data and set stores
          try {
            await productStore.fetchProductData(this.code);
          } catch (err) {
            console.error('Failed to fetch product data!', err);

            this.loading.app = false;
            this.loading.splash = false;
            throw err;
          }

          // Set any payment methods that will not be shown in popup to an initialisation state of not enabled
          paymentButtonTypes.forEach((paymentButtonType) => {
            if (!this.paymentPopupState.paymentConfig.paymentMethods.includes(paymentButtonType)) {
              this.setPaymentButtonInitialised(paymentButtonType, 'NOT_ENABLED');
            }
          });

          this.loading.app = false;
          this.loading.splash = true;

          setTimeout(() => {
            this.loading.splash = false;
          }, 2000);
        } else {
          this.code = code!;

          // Fetch static Product data and set stores
          try {
            await productStore.fetchProductData(this.code);
          } catch (err) {
            console.error('Failed to fetch product data!', err);

            this.loading.app = false;
            this.loading.splash = false;
            throw err;
          }

          // TODO? document.title = this.product.name + " | " + this.advertiser.name;

          this.loading.app = false;
          this.loading.splash = true;

          setTimeout(() => {
            this.loading.splash = false;
          }, 3500);

          // Set product base Price and Availability
          await productStore.fetchProductPricingAndAvailability(this.code);

          // Initialise Cart
          if (productStore.getIsProductInStock) {
            await cartStore.init(this.code);
          }
        }
      } catch (error) {
        this.goToErrorPage({ error });
      }
    },

    setPaymentProviderDependentData() {
      const advertiserStore = useAdvertiserStore();

      // Do not attempt to load Apple Pay when this initial check indicates that it cannot be supported
      if (!this.isPaymentPopup && !this.getIsApplePaySupported) {
        this.setPaymentButtonInitialised('applepay', 'NOT_SUPPORTED');
      }
    },

    /**
     * Load Assets
     * @todo - this was taken from prototype. Look into a better way of doing this
     */
    loadJsAssets() {
      // Loquate (Address lookup)
      (function (n, t, i, r) {
        var u, f;
        (n[i] = n[i] || {}),
          (n[i].initial = {
            accountCode: 'SMART11286',
            host: 'SMART11286.pcapredict.com',
          }),
          (n[i].on =
            n[i].on ||
            function () {
              (n[i].onq = n[i].onq || []).push(arguments);
            }),
          (u = t.createElement('script')),
          (u.async = !0),
          (u.src = r),
          (f = t.getElementsByTagName('script')[0]),
          f.parentNode.insertBefore(u, f);
      })(window, document, 'pca', '//SMART11286.pcapredict.com/js/sensor.js');
    },

    closeCheckout() {
      parent.window.postMessage('closeCheckout', '*');
    },

    // TODO - for stripe?
    goToPreviousStepInCheckout(router: Router) {
      const advertiserStore = useAdvertiserStore();
      const paymentProvider = advertiserStore.paymentProvider;

      let checkoutSteps: {
        name: string;
      }[];

      let checkoutViewName = '';

      switch (paymentProvider) {
        case 'braintree':
          checkoutViewName = 'BraintreeCheckout';
          break;

        case 'adyen':
          checkoutViewName = 'AdyenCheckout';
          break;

        case 'ppcp':
          checkoutViewName = 'PPCPCheckout';
          break;

        default:
          break;
      }

      switch (paymentProvider) {
        case 'braintree':
        case 'adyen':
        case 'ppcp':
          checkoutSteps = [
            {
              name: this.isPDPView ? 'ShopProduct' : 'Product',
            },
            {
              name: 'Shipping',
            },
            {
              name: checkoutViewName,
            },
          ];
          break;

        default:
          checkoutSteps = [
            {
              name: this.isPDPView ? 'ShopProduct' : 'Product',
            },
          ];
          break;
      }

      const currentStepIndex = checkoutSteps.findIndex((step) => {
        return step.name === router.currentRoute.value.name;
      });

      const previousStepIndex = currentStepIndex > 0 ? currentStepIndex - 1 : 0;

      router.replace({ name: `${checkoutSteps[previousStepIndex].name}` });
    },

    async goToErrorPage({ error, isSkipLogging = false }) {
      const router = await getRouter();

      this.endAppLoading();

      router.replace({
        name: 'Error',
        query: {
          error: JSON.stringify(error),
          isSkipLogging: isSkipLogging ? 'true' : 'false',
          shortCode: this.code, // DONOTCOMMIT TODO is this required?
        },
      });
    },

    startAppLoading() {
      this.loading.app = true;
    },

    endAppLoading() {
      this.loading.app = false;
    },

    xhrTracker: {
      blocksUI: [],
    },

    addToXhrTracker(id, type) {
      if (!this.xhrTracker[type].includes(id)) {
        this.xhrTracker[type].push(id);
      }
    },

    removeFromXhrTracker(id, type) {
      const index = this.xhrTracker[type].indexOf(id);
      this.xhrTracker[type] = this.xhrTracker[type].splice(index, 1);
    },

    clearXhrTracker() {
      this.xhrTracker = getInitialState().xhrTracker;
    },

    /**
     * @param isError {boolean} indicates that initialisation failed
     */
    setPaymentButtonInitialised(
      btnFlag: PaymentButtonType,
      initState: PaymentButtonInitialisationStateType,
    ) {
      this.initialisedPaymentButtons[btnFlag as PaymentButtonType] = initState;

      // Set the flag in state to indicate whether all buttons are initialised
      this.isPaymentButtonsInitialised = this.checkIsPaymentButtonsInitialised();
    },

    checkIsPaymentButtonsInitialised(isTimeoutRoutine = false) {
      return Object.entries(this.initialisedPaymentButtons).every(([btnFlag, val]) => {
        const isInitialisationAttemptComplete = val !== null;

        if (!isInitialisationAttemptComplete && isTimeoutRoutine) {
          const errMsg = `Payment method '${btnFlag}' failed to initialise successfully in alotted timeout of ${constants.PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT}ms`;
          console.error(errMsg);
          Sentry.captureException(errMsg);
        }

        return isInitialisationAttemptComplete;
      });
    },

    /**
     * After a period of time call this function to asses which payment buttons have not yet initialised successfully.
     * Logs an error for each one that hasn't yet.
     * You can then check the value of this.initialisedPaymentButtons to conditionally render out only the buttons that have
     * initialised successfully at this time.
     */
    assessAvailablePaymentButtonsAfterInitialisationAttempted() {
      if (this.isPaymentButtonsInitialised) {
        return;
      }

      this.checkIsPaymentButtonsInitialised(true);

      // Now free up the UI to conditionally render just the buttons that initialised successfully.
      this.isPaymentButtonsInitialised = true;
    },

    onLaunchPaymentPopup(paymentMethods: PaymentButtonType[]) {
      const cartStore = useCartStore();
      const paymentStore = usePaymentStore();
      const productStore = useProductStore();

      this.paymentPopupState.cartJwt = cartStore.cartJwt;
      this.paymentPopupState.paymentConfig = {
        ...paymentStore.paymentConfig,
        paymentMethods,
      };

      if (productStore.isProductHasVariants) {
        this.paymentPopupState.selectedVariant = cartStore.getSelectedProductVariant;
      }

      this.paymentPopupState.launchState = 'IS_LAUNCHING';
    },

    onPaymentPopupClosed() {
      this.paymentPopupState.launchState = 'IS_CLOSED';
    },

    async doPaymentPopupErrorPageTransfer({ error }: { error: Error }) {
      window.opener.ParentVueInstance.goToErrorPage({ error });
      window.close();
    },
  },

  getters: {
    getIsShopFrontInitialised: () => {
      const advertiserStore = useAdvertiserStore();
      const cartStore = useCartStore();
      const productStore = useProductStore();

      return (
        advertiserStore.isAdvertiserInitialised &&
        cartStore.isCartInitialised &&
        productStore.isProductsInitialised
      );
    },
    getIsShowPricingElements: (state) => {
      const cartStore = useCartStore();
      const productStore = useProductStore();

      if (!state.getIsShopFrontInitialised) {
        return false;
      }

      return (
        (cartStore.cart?.pricing && !productStore.isProductHasVariants) ||
        cartStore.getIsProductOptionsSelectionValid
      );
    },
    getFallbackUrl: () => {
      const advertiserStore = useAdvertiserStore();
      const productStore = useProductStore();

      return productStore.product?.fallbackUrl || advertiserStore.redirectUrl;
    },

    // TODO unit tests
    getIsApplePaySupported: (state) => {
      const advertiserStore = useAdvertiserStore();
      const paymentProvider = advertiserStore.paymentProvider!;
      let windowApiIsCompatible = false;

      const requiredVersion = constants.APPLE_PAY_REQUIRED_VERSION[paymentProvider];

      if (isNaN(requiredVersion)) {
        throw new Error('Unable to get required Apple Pay version!');
      }

      if (state.userSupportedApplePayVersion !== null) {
        // We have a value passed down from the parent page check
        if ((state.userSupportedApplePayVersion || -1) >= requiredVersion) {
          // The value passed down is a compatible version
          windowApiIsCompatible = true;
          console.log(
            `User with Apple Pay support version ${state.userSupportedApplePayVersion} was granted the Apple Pay feature.`,
          );
        } else {
          const msg = `A user with Apple Pay support version ${state.userSupportedApplePayVersion} was denied the Apple Pay feature.`;

          console.debug(msg);
          Sentry.captureException(msg);
        }
      } else {
        // We can't find the value passed down from the parent page check, perhaps the checkout page was refreshed
        // or we're in frameless development mode. So let's try the check again inside the Checkout.
        try {
          windowApiIsCompatible = !!(
            window.ApplePaySession &&
            window.ApplePaySession.supportsVersion(requiredVersion) &&
            window.ApplePaySession.canMakePayments()
          );
        } catch (err) {
          console.info(
            'Unable to check for Apple Pay support due to browser security profile, likely an incompatible version. ApplePay will not be offered.',
          );

          Sentry.captureException(err);
        }
      }

      return windowApiIsCompatible;
    },
    getIsShowHeaderCloseButton(): (route) => boolean {
      return (route) => {
        const productStore = useProductStore();

        if (productStore.isProductGalleryOpen) {
          // At time of writing our designs can lead to users clicking the App Close icon when they really just wanted
          // to close the current Gallery Image. So this solution removes the header close button when the gallery is open.
          return false;
        }

        return (
          ['Product', 'Thankyou', 'Error', 'ProductNotFound', 'ProductNotFoundPDP'].includes(
            route.name,
          ) &&
          !this.isPreviewMode &&
          !this.isPaymentPopup
        );
      };
    },
  },
});
