<template>
  <div>
    <svg width="56" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2227_4876)">
        <path
          d="M2.72749 7.76824C2.90204 7.86173 3.15269 7.96054 3.47763 8.06557C4.04693 8.24362 4.50435 8.50268 4.84988 8.84184C5.19539 9.1819 5.36817 9.58606 5.36817 10.0552C5.36817 10.7184 5.12468 11.2258 4.63592 11.5775C4.14807 11.9291 3.50984 12.1054 2.72123 12.1054C2.30678 12.1054 1.86547 12.035 1.39732 11.8935C0.930052 11.7519 0.56573 11.5801 0.305242 11.3781L0 11.135L0.914833 9.76411C1.15831 9.97419 1.44923 10.1522 1.7867 10.2982C2.12417 10.4442 2.4312 10.5163 2.7078 10.5163C3.0408 10.5163 3.20818 10.4113 3.20818 10.2012C3.20818 10.0561 3.10435 9.92791 2.89758 9.8193C2.6899 9.70982 2.3954 9.59051 2.01317 9.46143C0.874553 9.07329 0.305242 8.42257 0.305242 7.50832C0.305242 7.11217 0.418925 6.76676 0.647187 6.47122C0.874553 6.17566 1.18427 5.94956 1.57455 5.79197C1.96483 5.63442 2.3954 5.55518 2.86714 5.55518C3.33887 5.55518 3.76945 5.62372 4.15973 5.7617C4.55 5.89879 4.9528 6.11336 5.36726 6.40444L4.45242 7.7994C4.22506 7.60534 3.97442 7.44778 3.70231 7.32671C3.43017 7.20564 3.17149 7.1451 2.92801 7.1451C2.61918 7.1451 2.46432 7.24215 2.46432 7.43622C2.46432 7.5653 2.55205 7.67657 2.7266 7.77003L2.72749 7.76824Z"
          fill="currentColor"
        />
        <path
          d="M10.1872 8.70835C10.1872 8.34424 10.1039 8.06116 9.9374 7.85908C9.77093 7.65701 9.53638 7.55551 9.23562 7.55551C8.93486 7.55551 8.68421 7.65255 8.50965 7.84662C8.33512 8.04069 8.24292 8.30775 8.23486 8.64692V11.9834H6.03906V3.4917H8.23486V6.40269C8.68153 5.8365 9.28395 5.55342 10.0403 5.55342C10.504 5.55342 10.9122 5.65669 11.2667 5.86232C11.6203 6.06885 11.8951 6.35728 12.0902 6.7294C12.2854 7.10152 12.3829 7.52614 12.3829 8.00328V11.9825H10.1872V8.70746V8.70835Z"
          fill="currentColor"
        />
        <path
          d="M30.8616 5.6753H32.5696V7.43438H30.8616V9.4961C30.8616 9.90025 31.0443 10.1032 31.4103 10.1032C31.6538 10.1032 31.9593 10.0623 32.3252 9.98215L32.5696 11.8018C32.0899 12.0038 31.4999 12.1053 30.8008 12.1053C30.1421 12.1053 29.622 11.929 29.2396 11.5774C28.8575 11.2258 28.6669 10.7344 28.6669 10.1041V7.43527H27.5693V5.6762H28.6669L29.0328 4.2207H30.8624V5.6762L30.8616 5.6753Z"
          fill="currentColor"
        />
        <path
          d="M37.3875 8.70835C37.3875 8.34424 37.3043 8.06116 37.1378 7.85908C36.9712 7.65701 36.7367 7.55551 36.4358 7.55551C36.1351 7.55551 35.8846 7.65255 35.71 7.84662C35.5355 8.04069 35.4433 8.30775 35.4351 8.64692V11.9834H33.2393V3.4917H35.4351V6.40269C35.8829 5.8365 36.4841 5.55342 37.2407 5.55342C37.7043 5.55342 38.1135 5.65669 38.4671 5.86232C38.8206 6.06798 39.0955 6.35728 39.2907 6.7294C39.4858 7.10152 39.5831 7.52614 39.5831 8.00328V11.9825H37.3875V8.70746V8.70835Z"
          fill="currentColor"
        />
        <path
          d="M40.4375 5.67527H42.3887L42.499 6.80317C42.9465 5.96992 43.6948 5.5542 44.743 5.5542L44.6946 7.88299L44.2435 7.79843C44.1219 7.77438 44.0082 7.76192 43.9016 7.76192C43.479 7.76192 43.1614 7.9195 42.9501 8.23464C42.7389 8.54975 42.6334 8.96194 42.6334 9.47201V11.9833H40.4375V5.67527Z"
          fill="currentColor"
        />
        <path
          d="M49.4392 5.67529H51.6351V11.9833H49.6836L49.586 11.1341C49.1304 11.7813 48.4796 12.1044 47.6345 12.1044C46.9274 12.1044 46.3598 11.8801 45.933 11.4314C45.5059 10.9827 45.293 10.3898 45.293 9.65454V5.67529H47.4886V8.9504C47.4886 9.31448 47.572 9.59756 47.7383 9.79966C47.9049 10.0017 48.1385 10.1032 48.4403 10.1032C48.7653 10.1032 49.013 9.99995 49.1841 9.79342C49.3549 9.58689 49.4401 9.30647 49.4401 8.9504V5.67529H49.4392Z"
          fill="currentColor"
        />
        <path
          d="M18.5044 8.90675C18.4543 8.87203 18.4543 8.79814 18.5044 8.76252L19.8104 7.83225C19.7379 7.58655 19.6376 7.35241 19.506 7.13077C19.2133 6.63758 18.8043 6.25123 18.2797 5.9726C17.7551 5.69308 17.1635 5.5542 16.5046 5.5542C15.8458 5.5542 15.2604 5.69753 14.7233 5.98506C14.1862 6.27259 13.7637 6.66874 13.4549 7.17349C13.1461 7.67915 12.9912 8.24354 12.9912 8.86579C12.9912 9.48806 13.1371 10.0346 13.4307 10.5278C13.7234 11.021 14.1325 11.4073 14.6571 11.686C15.1816 11.9646 15.7733 12.1044 16.4321 12.1044C17.0909 12.1044 17.6764 11.961 18.2135 11.6735C18.7506 11.386 19.173 10.9898 19.4819 10.4842C19.6126 10.2705 19.7146 10.0444 19.7898 9.80941L18.5053 8.90585L18.5044 8.90675ZM17.7489 8.83552C17.7489 9.18359 17.6289 9.48271 17.389 9.73286C17.1492 9.98388 16.8421 10.1094 16.4679 10.1094C16.0938 10.1094 15.7975 9.98657 15.5531 9.73908C15.3096 9.49251 15.187 9.19159 15.187 8.83552C15.187 8.83462 15.187 8.83373 15.187 8.83286C15.187 8.83196 15.187 8.83107 15.187 8.83017C15.187 8.483 15.307 8.18301 15.5468 7.93285C15.7867 7.6827 16.0938 7.55718 16.4679 7.55718C16.8421 7.55718 17.1393 7.68091 17.3828 7.92751C17.6263 8.1741 17.7489 8.47589 17.7489 8.83107C17.7489 8.83196 17.7489 8.83286 17.7489 8.83375C17.7489 8.83462 17.7489 8.83552 17.7489 8.83642V8.83552Z"
          fill="currentColor"
        />
        <path
          d="M27.1113 7.16725C26.8472 6.67409 26.4847 6.2815 26.0255 5.99041C25.5663 5.6993 25.056 5.5542 24.4948 5.5542C23.7303 5.5542 23.1037 5.84974 22.6159 6.43995L22.4449 5.67527H20.6152V7.55362H24.0373C24.4035 7.55362 24.7078 7.67646 24.9522 7.92395C25.1957 8.17052 25.3183 8.4723 25.3183 8.82751C25.3183 9.18269 25.1984 9.4747 24.9585 9.72485C24.8367 9.85214 24.698 9.9465 24.5413 10.0097C24.3873 10.0729 24.2173 10.1041 24.0302 10.1041C24.0105 10.1041 23.9917 10.1023 23.9729 10.1014H20.6152V13.9249H22.811V11.5106C23.2416 11.9068 23.7786 12.1053 24.4214 12.1053C24.9746 12.1053 25.4884 11.9575 25.9646 11.6628C26.4408 11.3673 26.8168 10.9649 27.0925 10.4557C27.3691 9.9465 27.5069 9.39191 27.5069 8.79369C27.5069 8.19547 27.3744 7.66134 27.1104 7.16815L27.1113 7.16725Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2227_4876">
          <rect width="56" height="15.9007" fill="white" transform="translate(0 0.0244141)" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
