<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" fill="#55575E">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.15 7.141 1.125 1.257l.699-.715L7.5 6.084 13.177.542l.699.715-6.027 5.884a.5.5 0 0 1-.698 0Z"
    />
    <path
      d="M1.124 1.257.767.907a.5.5 0 0 0 .008.708l.35-.358Zm6.027 5.884.35-.358-.35.358ZM1.823.541l.35-.357a.5.5 0 0 0-.708.008l.358.35ZM7.5 6.085l-.35.358a.5.5 0 0 0 .7 0l-.35-.358ZM13.177.542l.358-.35a.5.5 0 0 0-.707-.008l.35.358Zm.699.715.35.358a.5.5 0 0 0 .008-.707l-.358.349ZM7.849 7.141l.35.358-.35-.358ZM.775 1.615l6.027 5.884.698-.716L1.474.9l-.699.716Zm.69-1.423L.767.908l.715.698.699-.715-.716-.699ZM7.85 5.727 2.172.184l-.698.715L7.15 6.442l.698-.715ZM12.83.184 7.15 5.727l.698.715L13.527.9l-.699-.715Zm1.405.724-.699-.716-.715.699.698.715.716-.698Zm-6.035 6.59 6.026-5.883-.698-.716L7.5 6.783 8.2 7.5Zm-1.397 0a1 1 0 0 0 1.397 0L7.5 6.784l-.698.716Z"
    />
  </svg>
</template>