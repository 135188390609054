import posthog from 'posthog-js';

/**
 * ENVIRONMENTS
 * https://posthog.com/tutorials/multiple-environments
 *
 * TODO's
 * https://posthog.com/tutorials/next-steps-after-installing
 *  - Slack notifications - error in PROD, sales in PROD?
 *  - data exports
 */

export function getPosthog() {
  const clientKey = import.meta.env.VITE_POSTHOG_CLIENT_KEY;
  const isTestmode = window.Cypress || import.meta.env.VITE_APP_IS_IN_E2E === 'true';
  const isDoInit = !isTestmode && !posthog.__loaded;

  if (isDoInit) {
    if (!clientKey) {
      throw new Error('Missing Posthog Client Key!');
    }

    posthog.init(clientKey, {
      api_host: 'https://eu.i.posthog.com',
      persistence: 'memory', // IMPORTANT DO NOT REMOVE THIS LINE!
      person_profiles: 'identified_only',
    });
  }

  return posthog;
}
