import { defineStore } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePublisherStore } from '@/stores/PublisherStore';
import { useCartStore } from '@/stores/CartStore';
import { useMainStore } from '@/stores/MainStore.js';
import { getProduct, getProductPricingAndAvailability } from '@/api/product';

import { getRouter } from '@/router';
import { getOptionedProductHash } from '@/helpers/productVariants';
import { Utils } from '@/helpers/utils';

import type { App } from 'vue';

import {
  ProductPricingAndAvailablitySimple,
  ProductPricingAndAvailablityConfigurable,
  type ProductBase,
  type ProductPricingAndAvailablitySimpleProps,
  type ProductPricingAndAvailablityConfigurableProps,
} from '@/types/product.types';
import { ProductVariantsLookupError, InvalidProductCodeError } from '@/types/errors.types';

type DiscountLookupState =
  | null
  | 'LOOKUP_IN_PROGRESS'
  | 'LOOKUP_ERRED'
  | 'DISCOUNT_APPLIED'
  | 'DISCOUNT_NOT_APPLICABLE'
  | 'DISCOUNT_REVOKED';

type State = {
  product: ProductBase | null;
  productPricingAndAvailability:
    | ProductPricingAndAvailablitySimple
    | ProductPricingAndAvailablityConfigurable
    | null;
  productDescriptionOpen: boolean;
  isProductGalleryOpen: boolean; // TODO this should probably be in the main store?
  discountLookupState: DiscountLookupState;
  isShowStickyApplePaySummary: boolean;
  products: []; // TODO
  productVariants: []; // TODO
  isProductHasVariants: boolean;
  variantsMatrix: Record<string, ToDo>;
  isProductsInitialised: boolean;
};

const getInitialState = (): State => ({
  product: null,
  productPricingAndAvailability: null,
  productDescriptionOpen: false,
  isProductGalleryOpen: false,
  discountLookupState: null,
  isShowStickyApplePaySummary: false,
  products: [],
  productVariants: [],
  isProductHasVariants: false,
  variantsMatrix: {},
  isProductsInitialised: false,
});

export const useProductStore = defineStore('productStore', {
  state: (): State => {
    return getInitialState();
  },
  actions: {
    async fetchProductData(productCode: string) {
      const router = await getRouter();
      const advertiserStore = useAdvertiserStore();
      const publisherStore = usePublisherStore();
      const mainStore = useMainStore();
      let productLookupResult;

      try {
        productLookupResult = await getProduct(productCode);
      } catch (err) {
        if (err.response?.status === 404) {
          let responseData;

          console.info('Product could not be found on lookup!', err);

          responseData = err.response?.data;

          if (responseData.retailer) {
            // The product code is valid
            advertiserStore.setData(responseData.retailer);
            router.replace({ name: 'ProductNotFound' });

            return Promise.reject('Product not found, redirecting to Product Not Found page...');
          } else {
            // The product code is not valid
            mainStore.code = null;
            mainStore.goToErrorPage({
              error: new InvalidProductCodeError(),
            });

            return Promise.reject('Product code invalid, redirecting to Error page...');
          }
        } else {
          throw err;
        }
      }

      const { product, retailer, publisher } = productLookupResult!.data.data;

      this.product = {
        id: product.id,
        name: product.name,
        shortDescription: product.short_description,
        description: product.description,
        images: product.images || [],
        displayAttributes: product.display_attributes,
        sku: product.sku,
        fallbackUrl: product.fallback_url,
      } as ProductBase;

      this.isProductHasVariants = !!product.variants.products?.length;

      if (this.isProductHasVariants) {
        this.products = product.variants.products; // TODO not sure about the naming...
        this.productVariants = product.variants.options; // TODO not sure about the naming...

        this.product.images = this.aggregateImagesFromVariants(
          product.images,
          product.variants.products.map((v: Record<'image', string>) => v.image),
        );
      }

      this.initVariantsMatrix();

      advertiserStore.setData(retailer);
      publisherStore.setData(publisher);

      if (mainStore.isPaymentPopup) {
        this.isProductsInitialised = true;
      }
    },

    async fetchProductPricingAndAvailability(productCode: string) {
      const result: {
        data: {
          product:
            | ProductPricingAndAvailablitySimpleProps
            | ProductPricingAndAvailablityConfigurableProps;
        };
      } = await getProductPricingAndAvailability(productCode);

      const { product: pricingAndAvailability } = result.data;

      if (this.isProductHasVariants) {
        const prAndAvail = pricingAndAvailability as ProductPricingAndAvailablityConfigurableProps;

        this.productPricingAndAvailability = new ProductPricingAndAvailablityConfigurable(
          prAndAvail,
        );

        this.updateVariantsMatrixWithAvailability();
      } else {
        const prAndAvail = pricingAndAvailability as ProductPricingAndAvailablitySimpleProps;

        this.productPricingAndAvailability = new ProductPricingAndAvailablitySimple(prAndAvail);
      }

      this.isProductsInitialised = true;
    },

    initVariantsMatrix() {
      if (this.isProductHasVariants) {
        if (!this.products?.length || !this.productVariants?.length) {
          throw new Error(
            'Product was specified as having variants but incomplete variant data was supplied!',
          );
        }

        this.variantsMatrix = this.products.reduce((acc, cur) => {
          const key = getOptionedProductHash(
            cur.variantOptions.map(({ variantCode, variantId }) => ({
              variantCode,
              val: variantId,
            })),
          );

          acc[key] = cur;
          return acc;
        }, {});
      }
    },

    aggregateImagesFromVariants(
      productImages: ProductBase['images'],
      variantImages: string[],
    ): ProductBase['images'] {
      const rtn = [...productImages];

      for (const i of variantImages) {
        if (!rtn.find((r) => r.src === i)) {
          rtn.push({ src: i, caption: '' });
        }
      }

      return rtn;
    },

    updateVariantsMatrixWithAvailability() {
      const prAndAvail = this
        .productPricingAndAvailability as ProductPricingAndAvailablityConfigurable;

      this.variantsMatrix = Object.entries(this.variantsMatrix).reduce(
        (acc, [productHash, productProps]) => {
          const matchedPrAndAvailVariant = prAndAvail.variants.find(
            (variant) => variant.sku === productProps.sku,
          );

          if (!matchedPrAndAvailVariant) {
            throw new Error('Could not match matrix SKU to pricing and availability sku!');
          }

          acc[productHash] = {
            ...productProps,
            isInStock: matchedPrAndAvailVariant.isInStock,
          };

          return acc;
        },
        {} as Record<string, ToDo>,
      );
    },

    closeGalleryOverlay() {
      this.isProductGalleryOpen = false;
    },

    async toggleGalleryOverlay() {
      const appInstance: App = this.$app;

      Utils.debounce(() => {
        this.isProductGalleryOpen = !this.isProductGalleryOpen;

        appInstance?.config.globalProperties.$dialogueListener({
          isDialogueInEffect: this.isProductGalleryOpen,
          onDialogueClose: () => {
            this.isProductGalleryOpen = false;
          },
        });
      }, 100);
    },

    setDiscountLookupState(discountState: DiscountLookupState) {
      this.discountLookupState = discountState;
    },
  },
  getters: {
    getProductUiOptions: ({ productVariants }) => {
      return productVariants.map((productVariant) => ({
        optionCode: productVariant.variantCode,
        label: productVariant.label,
        values: productVariant.values.map((val) => ({
          id: val.variantId,
          label: val.label,
        })),
      }));
    },

    getProductVariantFromOptions: (state) => {
      return (productOptions: Record<string, string>) => {
        let productVariant;

        const productHash = getOptionedProductHash(
          Object.entries(productOptions).map(([variantCode, optionValue]) => ({
            variantCode,
            val: optionValue,
          })),
        );

        productVariant = state.variantsMatrix[productHash];

        if (!productVariant) {
          throw new ProductVariantsLookupError(
            `Failed to get Product from Options, unable to match Product by hash - ${productHash}!`,
          );
        }

        return productVariant;
      };
    },

    getOptionsFromProductHash: (state) => {
      return (productHash: string) => {
        const product = state.variantsMatrix[productHash];

        if (!product) {
          throw new ProductVariantsLookupError(
            `Failed to get Options from Product Hash - ${productHash}!`,
          );
        }

        return product.variantOptions.map(
          ({ variantCode, variantId }: { variantCode: string; variantId: string }) => ({
            variantCode,
            variantId,
          }),
        );
      };
    },

    getPricingAndAvailabilityByVariantSku: (state) => {
      return (sku: string) => {
        return (
          state.productPricingAndAvailability as ProductPricingAndAvailablityConfigurable
        ).variants.find((v) => v.sku === sku);
      };
    },

    getIsAnyProductVariantAvailable(state): () => boolean {
      return () =>
        (state.productPricingAndAvailability as ProductPricingAndAvailablityConfigurable)!.variants.some(
          (v) => v.isInStock,
        );
    },
    getIsProductInStock(state): boolean {
      const cartStore = useCartStore();
      let rtn = cartStore.getIsProductRootAvailable;

      // Alternatively for variant products, we also need to check that at least one variant option is available
      if (rtn && state.isProductHasVariants) {
        rtn = state.getIsAnyProductVariantAvailable();
      }

      return rtn;
    },
  },
});
