import mitt from 'mitt';

import type { App } from 'vue';

const emitter = mitt();

export default {
  install: (app: App) => {
    // Send a message to the parent window
    app.config.globalProperties.$eventEmitter = emitter;
  },
};
