// Events that we want to track internally (as per the agreed API schema)
export const ExpectedInternalTrackingEvents = [
  'unhandledrejection_error_thrown',
  'handled_error_thrown',
  'error_page',
  'app_loaded',
  'view_product',
  'set_product_options',
  'quantity_increment',
  'quantity_decrement',
  'payment_type_selected',
  'payment_complete',
] as const;

// Events that we want to track externally
export const ExpectedExternalTrackingEvents = ['thankyou_page'] as const;

// All events are tracked externally
export const ExpectedEvents = [
  ...ExpectedInternalTrackingEvents,
  ...ExpectedExternalTrackingEvents,
] as const;

export type ExpectedInternalTrackingEventsType = (typeof ExpectedInternalTrackingEvents)[number];
export type ExpectedExternalTrackingEventsType = (typeof ExpectedExternalTrackingEvents)[number];
export type ExpectedEventsType =
  | ExpectedInternalTrackingEventsType
  | ExpectedExternalTrackingEventsType;
