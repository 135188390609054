import type { JwtPayload } from 'jwt-decode';

export const exampleCompleteCart = {
  id: 'cart-id-123',
  shortCode: '16CBAAAB',
  productId: 'my-product-id',
  productQuantity: 1,
  isDiscountApplied: false,
  pricing: {
    unitPrice: 100,
    totalUnitPrice: 100,
    totalDiscount: 25,
    totalDiscountedPrice: 75,
    shippingPrice: 20,
    grandTotal: 95,
  },
  shipping: {
    selectedShippingMethod: 'flatrate_smrt_flatrate',
  },
  features: {
    autoDiscount: false,
  },
};

export type CartJwtPayload = JwtPayload & {
  cart: object;
};

export type CartShipping = {
  selectedShippingMethod: ToDo;
};

export type CartSelectedShippingMethod = ToDo;

export type CartFeatureFlags = {
  autoDiscount: boolean;
};

export type CartInitialProps = {
  id: string;
  shortCode: string;
  productId: string;
  pspOrderId?: string;
  features?: CartFeatureFlags;
};

export type CartPricedProps = CartInitialProps & {
  pricing: {
    unitPrice: number;
    totalUnitPrice: number;
    totalDiscount: number;
    totalDiscountedPrice: number;
    shippingPrice: number;
    grandTotal: number;
  };
  productQuantity: number;
  isDiscountApplied: boolean;
};

export type CartShippingSetProps = CartPricedProps & {
  shipping: {
    selectedShippingMethod: ToDo;
  };
};

export type CartCompleteProps = CartShippingSetProps & {
  transactionId: string;
};

export class CartInitial {
  id: string;
  shortCode: string;
  productId: string;
  pspOrderId?: string;
  features?: CartFeatureFlags;

  constructor(props: CartInitialProps) {
    this.id = props.id;
    this.shortCode = props.shortCode;
    this.productId = props.productId;
    this.pspOrderId = props.pspOrderId;
    this.features = props.features;
  }
}

export class CartPriced extends CartInitial {
  productQuantity: number;
  isDiscountApplied: boolean;
  pricing: {
    unitPrice: number;
    totalUnitPrice: number;
    totalDiscount: number;
    totalDiscountedPrice: number;
    shippingPrice: number;
    grandTotal: number;
  };

  constructor(props: CartPricedProps) {
    super(props);

    this.productQuantity = props.productQuantity;
    this.pricing = props.pricing;
    this.isDiscountApplied = props.isDiscountApplied;
  }
}

export class CartShippingSet extends CartPriced {
  shipping: {
    selectedShippingMethod: CartSelectedShippingMethod;
  };

  constructor(props: CartShippingSetProps) {
    super(props);

    this.shipping = props.shipping;
  }
}

export class CartComplete extends CartShippingSet {
  transactionId: string;

  constructor(props: CartCompleteProps) {
    super(props);

    this.transactionId = props.transactionId;
  }
}

export type Cart = CartInitial | CartPriced | CartShippingSet | CartComplete | null;
